import React, { useState, useEffect } from 'react'
import HeaderSales from './common/HeaderSales'
import Footer from './common/Footer'
import { Link } from 'react-router-dom'
import axios from 'axios';
import * as Constants from "./Constants/index"
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/minimal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import swal from 'sweetalert';
import { saveAs } from 'file-saver';
import Oval from '../loader/CircleLoade';
import moment from 'moment';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
export default function PendingForApproval() {
    const [results, setResults] = useState([])
    const [idData, setIdData] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [isVisible, setIsVisible] = useState(false);
    const [modalShow, setModalShow] = useState(false)
    const [modalShow2, setModalShow2] = useState(false)
    const [status, setStatus] = useState('')
    const [reason, setReason] = useState('')
    const [remarks, setRemarks] = useState('')
    const [registartionShow, setRegistartionShow] = useState(false)
    let [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [pendingApprovalBy, setPendingApprovalBy] = useState([])


    const [bankDetailShow, setBankDetailShow] = useState('')
    const [upiDetailsShow, setUpiDetailsShow] = useState('')
    const [limit] = useState('10')
    const [errors, setErrors] = useState({});
    const [searchRecord, setSearchRecord] = useState()


    // registraion state
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [aadhar, setAadhar] = useState('')
    // const [mobileAadhar, setMobileAadhar] = useState('')
    // const [aadharFront, setAadharFront] = useState('');
    // const [aadharBack, setAadharBack] = useState();
    const [email, setEmail] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [address3, setAddress3] = useState('')
    const [state, setState] = useState('')
    // const [district, setDistrict] = useState('')
    const [city, setCity] = useState('')
    const [pincode, setPincode] = useState('')

    //bank details
    const [accountNo, setAccountNo] = useState('')
    const [accountType, setAccountType] = useState('')
    const [ifsc, setIfsc] = useState('')
    const [bankName, setBankName] = useState('')
    const [holderName, setHolderName] = useState('')
    const [cancelChk, setCancelChk] = useState('')
    const [upiId, setUpiId] = useState('')

    // id details
    const [idName, setIdName] = useState('')
    const [idNumber, setIdNumber] = useState('')
    const [idFrontImage, setIdFrontImage] = useState('')
    const [idBackImage, setIdBackImage] = useState('')
    const [panNumber, setPanNumber] = useState('');
    const [panFrontUrl, setPanFrontUrl] = useState('')
    const userType = localStorage.getItem('user_type')

    useEffect(() => {
        if (searchQuery === '') {
            // Call the function when the searchQuery becomes empty
            pendingRegistrationData();
            setSearchRecord()
        }
    }, [searchQuery]);
    useEffect(() => {
        if (registartionShow) {
            window.scrollTo({
                top: 500,
                behavior: 'smooth',
            });
        }
        window.addEventListener('scroll', handleScroll);

        pendingRegistrationData();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage, status, bankDetailShow, upiDetailsShow]);

    //pending registration data 
    let token = localStorage.getItem('token');
    const pendingRegistrationData = async () => {
        try {
            let endpoint = '';

            if (userType === 'SALES' || userType === 'MK') {
                endpoint = `user/manage/userBycityId?name=${searchQuery}&userType=MEMBER&status=VERIFIED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`;
            } else if (userType === 'SH' || userType === 'RO' || userType === 'HO') {
                endpoint = `user/manage/userByparentId?name=${searchQuery}&userType=MEMBER&status=VERIFIED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`;
            }
            setLoading(true);
            await axios.get(Constants.baseUrl + endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                }
            })
                .then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setResults(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                            setLoading(false);
                        } else if (res.data.code === 12002) {
                            setSearchRecord(res.data.output.results)
                            setLoading(false);
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    };


    // view registraion form
    const handleViewReg = async (id) => {
        let token = localStorage.getItem('token');

        try {
            await axios.get(Constants.baseUrl + `user/manage/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            window.scrollTo({
                                top: 600,
                                behavior: 'smooth',
                            });
                            const data = res.data.output;
                            setIdData(data);
                            // Set values for basic details
                            setName(data.name);
                            setMobile(data.mobile);
                            setEmail(data.email);
                            // Set values for user model KYC state
                            // setMobileAadhar(data.userModelKYC.aadharMobile)
                            // setAadhar(data.userModelKYC.aadharNumber)
                            // setAadharFront(data.userModelKYC.aadharFrontUrl);
                            // setAadharBack(data.userModelKYC.aadharBackUrl);

                            // Set values for address work
                            setAddress1(data.addressWork.workAddress1);
                            setAddress2(data.addressWork.workAddress2);
                            setAddress3(data.addressWork.workAddress3);
                            setState(data.addressWork.workState);
                            // setDistrict(data.addressWork.workDistrict);
                            setCity(data.addressWork.workCity);
                            setPincode(data.addressWork.workPincode);


                            setIdName(res.data.output.identityType)
                            setIdNumber(res.data.output.identityNumber)
                            setIdFrontImage(res.data.output.identityCardFrontUrl)
                            setIdBackImage(res.data.output.identityCardBackUrl)
                            setPanNumber(res.data.output.userModelKYC.panNumber)
                            setPanFrontUrl(res.data.output.userModelKYC.panFrontUrl)
                            // bank details
                            setAccountNo(data.bankDetail.bankAccountNo)
                            setAccountType(data.bankDetail.accountType)
                            setIfsc(data.bankDetail.ifsc)
                            setBankName(data.bankDetail.bankName)
                            setHolderName(data.bankDetail.holderName)
                            setCancelChk(data.bankDetail.cancelChk)
                            setUpiId(data.bankDetail.upiId)
                            if (data.bankDetail.bankAccountNo !== '' && data.bankDetail.upiId !== '') {
                                setBankDetailShow('bankdetails')
                                setUpiDetailsShow('upidetails')
                            }
                            if (data.bankDetail.bankAccountNo !== '') {
                                setBankDetailShow('bankdetails')
                            } else {
                                setBankDetailShow('')
                            }
                            if (data.bankDetail.upiId !== '') {
                                setUpiDetailsShow('upidetails')
                            } else {
                                setUpiDetailsShow('')
                            }
                            setRegistartionShow(true)

                        } else if (res.data.code === 12001) {
                            swal({
                                text: res.data.msg,
                                // icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }

    // select registraion form for approved or rejected
    const handleSelect = async (id) => {
        let token = localStorage.getItem('token');

        try {
            await axios.get(Constants.baseUrl + `user/manage/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setModalShow(true)
                        const data = res.data.output;
                        setIdData(data);
                        // Set values for basic details
                        setName(data.name);
                        setMobile(data.mobile);
                        setEmail(data.email);
                        // setMobileAadhar(data.userModelKYC.aadharMobile)
                        // Set values for user model KYC state
                        // setAadharFront(data.userModelKYC.aadharFrontUrl);
                        // setAadharBack(data.userModelKYC.aadharBackUrl);

                        // Set values for address work
                        setAddress1(data.addressWork.workAddress1);
                        setAddress2(data.addressWork.workAddress2);
                        setAddress3(data.addressWork.workAddress3);
                        setCity(data.addressWork.workCity);
                        setState(data.addressWork.workState);
                        setPincode(data.addressWork.workPincode);


                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }

    // display in login
    const displayInLogin = async (id) => {
        let token = localStorage.getItem('token');

        try {
            await axios.get(Constants.baseUrl + `user/manage/salesByUserId/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 201 && res.data.code === 10001) {
                        setPendingApprovalBy(res.data.output)
                        setModalShow2(true)
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }

    // update registration by approved or reject
    const updateRegistration = async (id) => {
        let token = localStorage.getItem('token');
        if (status === 'REJECT' && reason === '') {
            swal({
                text: 'Please select a reason',
                icon: 'error',
                timer: 2000,
                buttons: false,
            });
            return; // Return early if reason is not selected when status is 'reject'
        }

        if (reason === 'OTHER' && remarks === '') {
            swal({
                text: 'Please enter remarks',
                icon: 'error',
                timer: 2000,
                buttons: false,
            });
            return; // Return early if reason is 'other' but remarks are not entered
        }
        try {
            await axios.post(Constants.baseUrl + `user/manage/updatestatus/${id}`, {
                status: status,
                reason: reason,
                remarks: remarks
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            setModalShow(false)
                            swal({
                                text: res.data.output.status,
                                icon: 'success',
                                timer: 2000,
                                buttons: false,
                            });
                            setStatus('')
                            setReason('')
                            setRemarks('')


                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            });
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        });
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }

    const validationSearch = () => {
        let errors = {};
        if (!searchQuery) {
            errors.searchQuery = 'Name / Mobile Number is required'
        }
        return errors;
    };
    const handleSearch = () => {
        let token = localStorage.getItem('token');
        setErrors({});
        const validationErrors = validationSearch();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }
        try {
            let endpoint = '';

            if (userType === 'SALES' || userType === 'MK') {
                endpoint = `user/manage/userBycityId?name=${searchQuery}&userType=MEMBER&status=VERIFIED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`;
            } else if (userType === 'SH' || userType === 'RO' || userType === 'HO') {
                endpoint = `user/manage/userByparentId?name=${searchQuery}&userType=MEMBER&status=VERIFIED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`;
            }
            setLoading(true)
            axios.get(Constants.baseUrl + endpoint, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                }
            })
                .then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setLoading(false)
                            setResults(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                            setSearchRecord()
                        } else if (res.data.code === 12002) {
                            setLoading(false)
                            setSearchRecord(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);

                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }

    }
    const download = () => {
        let token = localStorage.getItem('token');
        try {
            setLoading(true)
            axios.post(Constants.baseUrl + `user/csv/download/usermanage?name=${searchQuery}&userType=MEMBER&status=VERIFIED`, {}, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'

            })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'pending_for_approval.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }


    const handleSearchQuery = (event) => {
        setSearchQuery(event.target.value)
        // if (event.target.value == '') {
        //     setSearchQuery('', pendingRegistrationData())
        // }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const closeModal = () => {
        setModalShow(false)
        setStatus('')
        setReason('')
        setRemarks('')
    }

    const handleSelection = (event) => {
        setStatus(event.target.value)
        setReason('')
        setRemarks('');
    }
    return (
        <>
            {loading ? <Oval /> : null}
            <HeaderSales />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="#Section1" role="tab" data-toggle="tab">Pending for Approval</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Name / Mobile No </label>
                                            <input className="form-control" placeholder="" type="text" value={searchQuery} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                        </div>
                                        {errors.searchQuery && (
                                            <div className="error-message">{errors.searchQuery}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label className="invisible" style={{ width: "100%" }}>hidden</label>
                                            <input type="button" className="btn login_btn" value="Search" id="btnSearch" onClick={handleSearch} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-header card-header-warning">
                                                <h4 className="card-title"> Mason Approval Pending
                                                    {searchRecord === null ? '' :
                                                        <Link to="#" className="pull-right" onClick={download}><i className="fa fa-file-excel-o"></i> Export</Link>}
                                                </h4>
                                            </div>
                                            <div className="card-body table-responsive">
                                                <table className="table table-striped mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th >S.No.</th>
                                                            <th >View User</th>
                                                            {userType === 'SALES' || userType === 'MK' ?
                                                                <th >Approval</th> : ''}
                                                            {userType === 'SALES' || userType === 'MK' ? '' :
                                                                <th>Display in Login</th>}
                                                            <th >Unique ID</th>
                                                            <th >User ID</th>
                                                            <th>User Name</th>
                                                            <th>City</th>
                                                            <th>District</th>
                                                            <th>State</th>
                                                            <th>Date of Registration</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="10" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {
                                                                results.map((item, index) => {
                                                                    const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                    const formattedDate = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                                    // console.log('format', formattedDate)
                                                                    return (
                                                                        <>
                                                                            <tr key={item.id}>
                                                                                <td>{serialNumber}</td>
                                                                                <td className='viewUserDetails table-cell' onClick={() => handleViewReg(item.id)}>view</td>
                                                                                {userType === 'SALES' || userType === 'MK' ?
                                                                                    <td className="table-cell"><Link to="#" className="select" onClick={() => handleSelect(item.id)}>Select</Link></td> : ''}
                                                                                {userType === 'SALES' || userType === 'MK' ? '' :
                                                                                    <td className='viewUserDetails table-cell' onClick={() => displayInLogin(item.id)}>view</td>}
                                                                                <td className="table-cell">{item.id}</td>
                                                                                <td className="table-cell">{item.mobile}</td>
                                                                                <td className="table-cell">{item.name}</td>
                                                                                <td className="table-cell">{item.addressWork.workCity}</td>
                                                                                <td className="table-cell">{item.addressWork.workdistrict}</td>
                                                                                <td className="table-cell">{item.addressWork.workState}</td>
                                                                                <td className="table-cell">{formattedDate}</td>
                                                                            </tr >
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {registartionShow === true ?
                <section className="blog-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="all-single-blog rpt">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Name*  </label>
                                                <input className="form-control" type="text" value={name} disabled />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Mobile No.*  </label>
                                                <input className="form-control" maxLength="10" type="text" value={mobile} disabled />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>ID Type.*  </label>
                                                <input className="form-control" maxLength="10" type="text" value={idName} disabled />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>ID No.*  </label>
                                                <input className="form-control" maxLength="10" type="text" value={idNumber} disabled />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>ID Front Image </label><Link to={idFrontImage} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
                                                <input className="form-control" id="" name="" type="file" disabled />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>ID Back Image </label><Link to={idBackImage} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
                                                <input className="form-control" id="" name="" type="file" disabled />
                                            </div>
                                        </div>

                                        {panNumber !== '' ?
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>PAN NO.*  </label>
                                                    <input className="form-control" maxLength="10" type="text" value={panNumber} disabled />
                                                </div>
                                            </div> : ''}
                                        {panNumber !== '' ?
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>PAN Card Image </label><Link to={panFrontUrl} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
                                                    <input className="form-control" id="" name="" type="file" disabled />
                                                </div>
                                            </div> : ''}

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Email ID </label>
                                                <input className="form-control" type="email" value={email} disabled />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Password*  </label>
                                                <input className="form-control" placeholder="Enter Password" type="text" value={password} />
                                            </div>
                                        </div> */}
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Address 1*</label>
                                                <input className="form-control" type="text" value={address1} disabled />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Addsress 2*</label>
                                                <input className="form-control" type="text" value={address2} disabled />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Address 3</label>
                                                <input className="form-control" type="text" value={address3} disabled />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>	State*</label>
                                                <input className="form-control" type="text" value={state} disabled />
                                            </div>
                                        </div>

                                        {/* <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>	District*</label>
                                                <input className="form-control" type="text" value={district} disabled />
                                            </div>
                                        </div> */}

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>	City*</label>
                                                <input className="form-control" type="text" value={city} disabled />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>	Pin Code*</label>
                                                <input className="form-control" type="text" value={pincode} onChange={(event) => setPincode(event.target.value)} disabled />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 col-xs-12">
                                       <h4 className="midhead">Mason Bank Or UPI Details  </h4>
                                   </div> */}
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <div className="h34">
                                                    {bankDetailShow === 'bankdetails' ?
                                                        <label className="radio-inline">
                                                            <input id="chckbanking" name="PaymentMode" type="checkBox" checked={bankDetailShow === 'bankdetails'} />
                                                            <span> Bank Details</span>
                                                        </label> : ''}
                                                    {upiDetailsShow === 'upidetails' ?
                                                        <label className="radio-inline">
                                                            <input id="chckupi" name="PaymentMode" type="checkBox" checked={upiDetailsShow === 'upidetails'} /> <span> UPI Details</span>
                                                        </label> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        {bankDetailShow === 'bankdetails' ?
                                            <div className="bankdetails" id="bankdetails">
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Account Holder Name </label>
                                                        <input autoComplete="nope" className="form-control" type="text" value={holderName} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Account Type </label>
                                                        <input className="form-control" type="text" value={accountType} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Bank Name </label>
                                                        <input className="form-control" type="text" value={bankName} disabled />
                                                        <span className="field-validation-valid errormsgr" data-valmsg-for="BankName" data-valmsg-replace="true" style={{ color: 'red' }}></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Account Number  </label>
                                                        <input autoComplete="nope" className="form-control" data-val="true" data-val-required="Account Number Required" id="txtacntno" maxLength="20" name="AccountNumber" type="text" value={accountNo} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>IFSC Code </label>
                                                        <input autoComplete="nope" className="form-control" maxLength="11" minLength="11" type="text" value={ifsc} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Cheque/Passbook </label><Link to={cancelChk} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
                                                        <input className="form-control" id="" name="" type="file" disabled />
                                                    </div>
                                                </div>
                                            </div> : ''}
                                        {upiDetailsShow === 'upidetails' ?
                                            <div id="upidetails">
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>UPI ID </label>
                                                        <input autoComplete="nope" className="form-control" type="text" value={upiId} disabled />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-3 col-sm-12 col-xs-12">
                                               <div className="form-group has-float-label style-two">
                                                   <label>UPI ID Screenshot *</label>
                                                   <input className="form-control" id="" type="file" onChange={handleUpiIdScreen} />
                                               </div>
                                           </div> */}
                                            </div> : ''
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                : ''}
            <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>
            <Modal
                isOpen={modalShow}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setModalShow(false)}
                style={customStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" onClick={closeModal}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-details">
                                <div className="row">
                                    <div className="form-group col-lg-12  col-md-12">
                                        <label>Select Status<span style={{ color: "red" }}></span></label>
                                        <select className="form-control2" value={status} onChange={handleSelection}>
                                            <option >Select Status</option>
                                            <option value="APPROVED" >Approve</option>
                                            <option value="REJECT">Reject</option>
                                        </select>
                                    </div>

                                    {status === 'REJECT' ?
                                        <div className="form-group col-lg-12  col-md-12">
                                            <label>Select Reason<span style={{ color: "red" }}>*</span></label>
                                            <select className="form-control2" value={reason} onChange={(event) => setReason(event.target.value)}>
                                                <option >Select Reason</option>
                                                <option value="Incorrect KYC Documents" >Incorrect KYC Documents</option>
                                                <option value="Not a Genuine Mason">Not a Genuine Mason</option>
                                                <option value="Duplicate Mason">Duplicate Mason</option>
                                                <option value="OTHER">Other</option>
                                            </select>
                                        </div> : ''}

                                    {reason === 'OTHER' && status === 'REJECT' ?
                                        <div className="form-group col-lg-12  col-md-12">
                                            <label>Enter Reason<span style={{ color: "red" }}>*</span></label>
                                            <textarea className="form-control txtreason" cols="50" rows="4" placeholder="Enter Reason" value={remarks} onChange={(event) => setRemarks(event.target.value)}></textarea>
                                        </div> : ''}
                                    <div className="form-group col-lg-12 col-md-12 text-center">
                                        {status === 'APPROVED' ?
                                            <input type="submit" className="btn login_btn" value="Approve" onClick={() => updateRegistration(idData.id)} /> : ''}
                                        {status === 'REJECT' ?
                                            <input type="submit" className="btn btn-reject" value="Reject" onClick={() => updateRegistration(idData.id)} /> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalShow2}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setModalShow2(false)}
                style={customStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" onClick={() => setModalShow2(false)}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-details">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-header card-header-warning">
                                                <h4 className="card-title">Sales / Marketing</h4>
                                            </div>
                                            <div className="card-body table-responsive">
                                                <table className="table table-striped mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Name</th>
                                                            <th>Mobile</th>
                                                            <th>Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pendingApprovalBy.map((item, index) => {
                                                            const serialNumber = index + 1;
                                                            const userTypeLabel = item.userType === 'MK' ? 'Marketing' : item.userType;
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td style={{ textAlign: 'center', fontSize: '16px' }}>{serialNumber}</td>
                                                                        <td style={{ textAlign: 'center', fontSize: '16px' }}>{item.name}</td>
                                                                        <td style={{ textAlign: 'center', fontSize: '16px' }}>{item.mobile}</td>
                                                                        <td style={{ textAlign: 'center', fontSize: '16px' }}>{userTypeLabel}</td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Footer />
        </>
    )
}
