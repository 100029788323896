import React, { useState, useEffect } from 'react'
import HeaderSales from './common/HeaderSales'
import Footer from './common/Footer'
import registred from '../assets/img/registred.png'
import approved from '../assets/img/approved.png'
import pending_approve from '../assets/img/pendingapprove.png'
import active_mason from '../assets/img/active_mason.png'
import inactive_mason from '../assets/img/inactive_mason.png'
import scanned_qrcode from '../assets/img/scanned_qrcode.png'
import rejected_scan from '../assets/img/total_rejected.png'
import total_star from '../assets/img/total_star.png'
import redeemstar from '../assets/img/redeemstar.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import * as Constants from "./Constants/index"
import DonutChart from './DonutChart';
import BarChart from './BarChart'
import Oval from '../loader/CircleLoade';

export default function SalesDashboard() {
    const [isVisible, setIsVisible] = useState(false);
    const [topMason, setTopMason] = useState([]);
    const [dashboard, setDashboard] = useState({});
    // const [totalMember, setTotalMember] = useState('')
    const [loading, setLoading] = useState(false);

    const userType = localStorage.getItem('user_type')


    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        let token = localStorage.getItem('token');
        try {
            let endpoint = '';

            if (userType === 'SALES') {
                endpoint = `user/manage/userBycityId?limit=10&userType=MEMBER&status=APPROVED&page=1&sortBy=totalPoints&sortAsc=false`;
            } else if (userType === 'SH' || userType === 'RO' || userType === 'HO' || userType === 'MK') {
                endpoint = `user/manage/userByparentId?limit=10&userType=MEMBER&status=APPROVED&page=1&sortBy=totalPoints&sortAsc=false`;
            }
            setLoading(true);
            axios.get(Constants.baseUrl + endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    program: Constants.program,
                    company: Constants.company
                }
            })
                .then((res) => {
                    if (res.status === 201 && res.data.output) {
                        setLoading(false);
                        setTopMason(res.data.output.results);

                    } else {
                        // swal(res.data.msg);
                    }
                })

        } catch (error) {
            console.log(error)
        }
        try {
            setLoading(true);
            axios.get(Constants.baseUrl + `program/dashboard/sales`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    program: Constants.program
                }
            })
                .then((res) => {
                    if (res.status === 201 && res.data.output) {
                        setLoading(false);
                        setDashboard(res.data.output);
                        // setTotalMember(parseInt(res.data.output.memberApproved) + parseInt(res.data.output.memberVerified) + parseInt(res.data.output.newSales) + parseInt(res.data.output.memberRejected))
                    } else {
                        // swal(res.data.msg);
                    }
                })

        } catch (error) {
            console.log(error)
        }

        // try {
        //     axios.post(Constants.baseUrl + `capture/qr/product_report_trend`, {}, {
        //         headers: {
        //             Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
        //             appVersion: Constants.av,
        //             platform: Constants.pt,
        //             program: Constants.program,
        //             company: Constants.company
        //         }
        //     })
        //         .then((res) => {
        //             if (res.status === 201) {
        //                 if (res.data.code === 10001) {

        //                 }
        //             } else {
        //                 // swal(res.data.msg);
        //             }
        //         })
        // } catch (error) {
        //     console.log(error)
        // }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            {loading ? <Oval /> : null}
            <HeaderSales />
            <section className="blog-area pt182">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog">
                                <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                        <div className="cardboard">
                                            <div className="row">
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-right border-bottom">
                                                        <div className="icon">
                                                            <img src={registred} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Total Registrations
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.members}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom ">
                                                        <div className="icon">
                                                            <img src={pending_approve} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Reg. by Sales/Mkt Team
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.newSales}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom  border-right">
                                                        <div className="icon">
                                                            <img src={pending_approve} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Pending Verification
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.pendingverification}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom ">
                                                        <div className="icon">
                                                            <img src={pending_approve} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Pending Approval
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.memberVerified}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-right">
                                                        <div className="icon">
                                                            <img src={inactive_mason} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Total Rejections
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.memberRejected}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance">
                                                        <div className="icon">
                                                            <img src={approved} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Total Approved
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.memberApproved}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                        <div className="cardboard">
                                            <div className="row">
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-right border-bottom">
                                                        <div className="icon">
                                                            <img src={scanned_qrcode} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Total QR Code Reg.
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.scanTotal}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom">
                                                        <div className="icon">
                                                            <img src={rejected_scan} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Total QR Code Rejections
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.scanRejected}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-right border-bottom">
                                                        <div className="icon">
                                                            <img src={total_star} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Total Stars Allocated
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.pointsGiven}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom">
                                                        <div className="icon">
                                                            <img src={redeemstar} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Total Stars Redeemed
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.pointsRedeemed}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-right">
                                                        <div className="icon">
                                                            <img src={active_mason} alt='' />

                                                        </div>

                                                        <div className="content">
                                                            <div className="text">
                                                                Total Active Masons
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard !== null || dashboard !== undefined ? dashboard.memberActive : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance  ">
                                                        <div className="icon">
                                                            <img src={inactive_mason} alt='' />
                                                        </div>
                                                        <div className="content">
                                                            <div className="text">
                                                                Total Inactive Masons
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.memberInActive}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-30">
																		<BarChart />
                                    <div className="col-md-6 col-sm-6 col-xs-12 col-23 mtop20">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title text-center">
                                                    Top 10 Masons
                                                </h3>
                                            </div>
                                            <div className="card-body">
                                                <table className="table table-striped  topMason mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Name</th>
                                                            <th>Stars</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {topMason && topMason.map(function (item, i) {
                                                            const name = item.name.split(' ')[0].length > 6 ? item.name.split(' ')[0] : item.name.split(' ')[0];
                                                            // const points = item.points >= 1000 ? (item.points / 1000).toLocaleString('en-IN') + ' L' : item.points;
                                                            return (

                                                                <tr key={item.id}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{name}</td>
                                                                    <td>{item.totalPoints}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <DonutChart />
                                </div>
                                <br />
                                <div className="row mt-15 hidden">
                                    <div className="col-md-3">
                                        <div className="info-box_1 nflink wd80">
                                            <Link to="">
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Login Report</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="info-box_1 nflink wd80">
                                            <Link to="">
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Enrollment Report </span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="info-box_1 nflink wd80">
                                            <Link to="">
                                                <div className="info-box-content">
                                                    <span className="info-box-text">QR transaction Report</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <div className="info-box_1 nflink wd80">
                                            <Link to="">
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Point Summary Report</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    {/* <!-- 	<div className="col-md-3">
                                                <div className="info-box_1 nflink wd80">
                                                    <Link to="">
                                                        <div className="info-box-content">
                                                            <span className="info-box-text">Others Report</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div> --> */}
                                    <div className="col-md-3">
                                        <div className="info-box_1 nflink wd80">
                                            <Link to="">
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Anomaly Report</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="info-box_1 nflink wd80">
                                            <Link to="">
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Blocked Member</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>
            <Footer />

        </>
    )
}
